<template>
  <div class="mobile-wrap">
    <div class="form-item">
      <MyField required v-model="state.old_mm" type="password" maxlength="16" label="原密码" placeholder="请输入原密码" />
    </div>
    <div class="form-item">
      <MyField required v-model="state.new_mm" type="password" maxlength="16" label="新密码" placeholder="请输入新密码" />
    </div>
    <div class="form-item">
      <MyField required v-model="state.confirm_mm" type="password" maxlength="16" label="确认密码" placeholder="请再次输入新密码" />
    </div>
    <div class="btn-wrap" @click="updateHandle">更新</div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { Toast } from 'vant'
import MyField from '@/components/my-input'
import { putPassword } from '@/api/'
import { useRouter } from 'vue-router'
export default {
  components: {
    MyField
  },
  setup () {
    const state = reactive({
      old_mm: '',
      new_mm: '',
      confirm_mm: ''
    })
    const router = useRouter()

    const updateHandle = () => {
      if (state.new_mm === '' || state.old_mm === '' || state.confirm_mm === '') {
        Toast('请填写必填项!')
      } else if (state.new_mm.length < 6 || state.confirm_mm.length < 6) {
        Toast('密码应为6~16位字母、数字或符号')
      } else if (state.new_mm === state.old_mm) {
        Toast('原密码与新密码一致，请修改！')
      } else {
        putPassword(state).then(res => {
          console.log(res)
          if (res.code === 9) {
            Toast.fail(res.message)
          }
          if (res.code === 200) {
            Toast.success({
              message: '修改成功',
              onClose: () => {
                router.back()
              }
            })
          }
        })
      }
    }

    return {
      state,
      updateHandle
    }
  }
}
</script>

<style lang="less" scoped>
.mobile-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 26px 38px 0 38px;
  .form-item {
    margin-bottom: 36px;
  }
  .vertifiy-wrap {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #007FFF;
    white-space: nowrap;
    .line {
      color: #B9C6D3;
      margin-right: 8px;
    }
  }
  .my-countdown {
    width: 81px;
    display: flex;
    justify-content: center;
    .line {
      color: #B9C6D3;
      margin-right: 8px;
      font-size: 14px;
    }
    .secends {
      color: #B9C6D3;
      margin-left: 4px;
      font-size: 14px;
    }
  }
  .btn-wrap {
    height: 44px;
    background: #007AFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
</style>
